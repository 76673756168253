<template>
  <div>
    <header class="container header_white">
      <Navbar logoColor="true"></Navbar>
    </header>

    <div class="bg_image-arrow" style="min-height: 70vh">
      <div class="container">
        <!-- OVERVIEW -->
        <section class="row mb-4">
          <h3 class="col-12 title_m title_darkblue mt-5">Umrežavanje srpskih istraživača širom sveta</h3>
          <div class="col-12">
            <span class="text_l title_darkblue text_normal">Pretražujte sveobuhvatnu bazu</span>
          </div>
        </section>

        <section v-if="isLoading" class="row">
          <div class="col-12 text-center">
            <Loader></Loader>
          </div>
        </section>

        <section v-else class="row">
          <!-- FILTERS -->
          <div class="col-12 mb-4 mb-lg-0 col-lg-3">
            <button class="w-100 btn button_red-tiny text-left d-block d-lg-none mb-3" type="button"
                    data-toggle="collapse" data-target="#researchers-filters" aria-expanded="false"
                    aria-controls="researchers-filters">
              <span class="pl-2">Filteri za pretragu</span>
            </button>
            <section class="row" :class="appendCollapseClass" id="researchers-filters">
              <div v-for="filter in filters" :key="filter.title" class="col-12 filter mb-2">

                <select v-if="filter.title == 'Oblast istrazivanja'" :id="filter.title" v-model="selectedFilters.oblastIstrazivanja"
                        class="select select-arrow w-100 mt-0">
                  <option value="" disabled selected class='d-none'>{{ filter.title }}</option>
                  <option v-for="option in filter.options" :key="option.name" :value="option.name">
                    {{ option.name }}
                  </option>
                </select>

                <select v-if="filter.title == 'Tip institucije'" :id="filter.title" v-model="selectedFilters.tipInstitucije"
                        class="select select-arrow w-100 mt-0">
                  <option value="" disabled selected class='d-none'>{{ filter.title }}</option>
                  <option v-for="option in filter.options" :key="option.name" :value="option.name">
                    {{ option.name }}
                  </option>
                </select>

                <select v-if="filter.title == 'Naziv Institucije'" :id="filter.title" v-model="selectedFilters.nazivInstitucije"
                        class="select select-arrow w-100 mt-0">
                  <option value="" disabled selected class='d-none'>{{ filter.title }}</option>
                  <option v-for="option in filter.options" :key="option.name" :value="option.name">
                    {{ option.name }}
                  </option>
                </select>

                <select v-if="filter.title == 'Drzava'" :id="filter.title" v-model="selectedFilters.Drzava"
                        class="select select-arrow w-100 mt-0">
                  <option value="" disabled selected class='d-none'>{{ filter.title }}</option>
                  <option v-for="option in filter.options" :key="option.name" :value="option.name">
                    {{ option.name }}
                  </option>
                </select>

              </div>
              <div class="col-12">
                <button type="button" class="w-100 btn button_red-small mb-1" style="font-size:12px"
                        @click="applyFilters()">
                  Primeni filtere
                </button>
                <button type="button" class="w-100 btn button_red-cancel" style="font-size:12px"
                        @click="resetFilters()">
                  Očisti filtere
                </button>
              </div>
            </section>
          </div>

          <!-- LIST OF RESEARCHERS -->
          <div class="col-12 col-lg-9">
            <section class="row text-center pb-5 mx-auto">
              <!-- RESEARCHER PROFILE -->
              <div v-if="!this.totalResults || this.totalResults == 0" class="col-12">
                <p class="text-center text_xl title_darkblue text_normal my-5">
                  Žao nam je. <br>
                  Nema rezultata koji odgovaraju Vašoj pretrazi.
                </p>
              </div>
              <div v-else class="col-12 col-lg-3 px-0 px-lg-1" v-for="(researcher, index) in researchers" :key="index">
                <div class="card py-3 mb-2">
                  <img v-if="!researcher.image_url" class="mx-auto img-fluid rounded-circle mb-3"
                       :src="require(`@/assets/default.png`)" :alt="researcher.name" width="90" height="90">
                  <img v-else class="mx-auto img-fluid rounded-circle mb-3"
                       :src="researcher.image_url" :alt="researcher.name" width="90" height="90">
                  <p class="mb-0 text_xs px-1" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ researcher.name }}
                  </p>
                  <p class="mb-0 text_xs px-1" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ researcher.title }}
                  </p>
                  <p class="text_xs px-1" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ researcher.institution }}
                  </p>
                  <router-link :to="`/istrazivaci/${researcher.url}`">
                    <button class="btn button_red-small" style="font-size: 10px !important;">
                      Pogledaj profil
                    </button>
                  </router-link>
                </div>
              </div>

              <!-- LOAD MORE RESEARCHERS -->
              <div class="col-12 my-4" v-if="selectedFilters.page !== totalPages">
                <div class="cursor text_xs title_darkblue text_normal text-center">
                  <span>Prikazuje {{ researchers.length }} od {{ totalResults }}</span>
                  <br>
                  <div id="showMoreProgresBar" class="mx-auto my-3">
                    <div :style="'width:'+ (100 * researchers.length / totalResults).toFixed(2)+'%'"></div>
                  </div>
                </div>
                <div @click="loadMoreResearchers()" class="cursor text_xl title_darkblue text_normal text-center">
                  <button class="btn button_red-small"
                          style="font-size: 12px !important; padding: 5px 50px !important;">
                    Prikaži više
                  </button>
                </div>
              </div>
            </section>
          </div>

        </section>
      </div>
    </div>
    <Footer bg="footer_bg-red"></Footer>
  </div>
</template>


<script>
import axios from 'axios';
import Loader from "@/components/shared/Loader";
import Navbar from "@/components/layout/Navbar";
import Footer from "@/components/layout/Footer";

export default {
  name: "Researchers",
  components: {
    Loader,
    Navbar,
    Footer
  },
  data() {
    return {
      selectedFilters: {
        page: 1,
        oblastIstrazivanja: '',
        tipInstitucije: '',
        nazivInstitucije: '',
        Drzava: ''
      },
      screenWidth: window.innerWidth,
      researchers: [],
      totalPages: 1,
      totalResults: 0,
      isLoading: false
    };
  },
  computed: {
    appendCollapseClass() {
      return this.screenWidth < 992 ? 'collapse' : 'collapsed';
    }
  },
  methods: {
    fetchResearchers() {
      return axios.get(`${process.env.VUE_APP_API_URL}/researchers/list/`);
    },
    fetchFilters() {
      return axios.get(`${process.env.VUE_APP_API_URL}/researchers/filters-all-public`);
    },
    initializeDefaultFilters() {
      this.filters.forEach(filter => {
        if (filter.options && filter.options.length > 0) {
          this.$set(this.selectedFilters, filter.title, '');
        }
      });
    },
    setPagination(paginationData) {
      this.selectedFilters.page = paginationData["current_page"] || 1;
      this.totalPages = paginationData["total_pages"] || 1;
      this.totalResults = paginationData["total_results"] || 0;
    },
    applyFilters() {
      this.isLoading = true;
      this.selectedFilters.page = 1;
      this.sendFilters();

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    resetFilters() {
      this.isLoading = true;
      this.initializeDefaultFilters();
      this.selectedFilters.page = 1;
      this.selectedFilters.oblastIstrazivanja= '';
      this.selectedFilters.tipInstitucije= '';
      this.selectedFilters.nazivInstitucije= '';
      this.selectedFilters.Drzava= '';
      this.sendFilters();

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },
    sendFilters() {
      axios.get(`${process.env.VUE_APP_API_URL}/researchers/search`
          + '?Oblast=' + this.selectedFilters.oblastIstrazivanja
          + '&Tip=' + this.selectedFilters.tipInstitucije
          + '&Naziv=' + this.selectedFilters.nazivInstitucije
          + '&Drzava=' + this.selectedFilters.Drzava
          + '&page=' + this.selectedFilters.page)
          .then(response => {
            if (response.data.current_page === 1) {
              this.researchers = response.data.researchers;
            } else {
              this.researchers = [...this.researchers, ...response.data.researchers];
            }
            this.setPagination(response.data);
          })
          .catch(error => {
            console.error('Error fetching researchers', error);
          });
    },
    loadMoreResearchers() {
      this.selectedFilters.page += 1;
      this.sendFilters();
    },
  },
  created() {
    this.isLoading = true;
    Promise.all([this.fetchResearchers(), this.fetchFilters()])
        .then(([researchersResponse, filtersResponse]) => {
          this.researchers = researchersResponse.data["researchers"];
          this.setPagination(researchersResponse.data);
          this.filters = filtersResponse.data["filters"];
          this.initializeDefaultFilters();
          this.isLoading = false;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
  },
}
</script>
